import React from 'react';
import { StyledCertDetailsTable } from './cert-details-table.styles';
import verifiedImg from '../../../images/verified.png';
import notVerifiedImg from '../../../images/not-verified.png';
import moment from 'moment';
import clipboardIcon from '../../../images/clipboard-icon.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {alertNotification} from '../../../helpers/notifications'

export const CertDetailsTable = ({title, cert}) => {
  
  const copyNotification = () => {
    alertNotification('info', 'Copied!', 3000);
  }

  const getMsDeployedVerifiedValue = () => {
    if(cert.mediaServicesDeployedIntStatus && cert.mediaServicesDeployedStageStatus && cert.mediaServicesDeployedTestStatus && cert.mediaServicesDeployedLiveStatus){
      return true;
    }else{
      return false;
    }
  }

  const statusText = cert.ca ? 'Deployed' : 'Verified';

  return(
    <StyledCertDetailsTable>
      <h2>{title}</h2>
      <table>
        <thead>
          <tr>
            <th>Properties</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
        {
          cert.isInterCert === true ? '' :
          cert.ca === true ? '' :
          <>
            <tr>
              <td>Verified</td>
              <td>{cert.verified? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}</td>
            </tr>
            <tr>
              <td>Registered As CA</td>
              <td>{cert.registeredAsCa? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}</td>
            </tr>
          </>
        }
          <tr>
            <td>Serial Number</td>
            <td>{cert.serialNumber}</td>
          </tr>
          <tr>
            <td colSpan="2"><b>Subject Details</b></td>
          </tr>
          <tr>
            <td>Subject CN</td>
            <td>{cert.subjectCN}</td>
          </tr>
          <tr>
            <td>Subject Organization</td>
            <td>{cert.subjectOrganization}</td>
          </tr>
          <tr>
            <td>Subject Organization Unit</td>
            <td>{cert.subjectOrganizationUnit}</td>
          </tr>
          <tr>
            <td>Subject Country</td>
            <td>{cert.subjectCountry}</td>
          </tr>
          <tr>
            <td>Subject Location</td>
            <td>{cert.subjectLocation}</td>
          </tr>
          <tr>
            <td colSpan="2"><b>Issuer Details</b></td>
          </tr>
          <tr>
            <td>Issuer CN</td>
            <td>{cert.issuerCN}</td>
          </tr>
          <tr>
            <td>Issuer Organization</td>
            <td>{cert.issuerOrganization}</td>
          </tr>
          <tr>
            <td>Issuer Organization Unit</td>
            <td>{cert.issuerOrganizationUnit}</td>
          </tr>
          <tr>
            <td>Issuer Country</td>
            <td>{cert.issuerCountry}</td>
          </tr>
          <tr>
            <td>Issuer Location</td>
            <td>{cert.issuerLocation}</td>
          </tr>
          <tr>
            <td colSpan="2"><b>Public Key Info</b></td>
          </tr>
          <tr>
            <td>Bit Length</td>
            <td>{cert.publicKeyInfo ? cert.publicKeyInfo.bitLength : ''}</td>
          </tr>
          <tr>
            <td>Algorithm</td>
            <td>{cert.publicKeyInfo ? cert.publicKeyInfo.algorithm : ''}</td>
          </tr>
          {
            cert.isInterCert === true ? '' :
            cert.ca === true ? '' :
              <>
                <tr>
                  <td colSpan="2"><b>User Agents: </b></td>
                </tr>
                
                {
                  cert.associatedUserAgents && cert.associatedUserAgents.length > 0 ?
                  cert.associatedUserAgents.map((agent, i) =>
                    <tr>
                      <td>Agent #{i + 1} </td>
                      <td>{agent}</td>
                    </tr>
                  ) :
                  <td colSpan="2">No data</td>
                }
              </>
          }
          <tr>
            <td>Valid Not Before</td>
            <td>{`${moment(cert.validFrom).format('DD/MM/YYYY')}`}</td>
          </tr>
          <tr>
            <td>Valid Not After</td>
            <td>{`${moment(cert.validTo).format('DD/MM/YYYY')}`}</td>
          </tr>
          <tr>
            <td>Base64</td>
            <td>
              <textarea className="base64Box" value={cert.base64} readOnly>
                {cert.base64}
              </textarea>
              <CopyToClipboard  text={cert.base64}>
                <img className="copyImg" src={clipboardIcon} onClick={copyNotification}/>
              </CopyToClipboard>
            </td>
          </tr>
          {
            cert.isInterCert === true ? '' :
            <>
              <tr>
                <td>Validate Client</td>
                <td>{cert.validateClient? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}</td>
              </tr>
              {
                !cert.externalSystemLinks ? '' :
                <tr>
                  <td colSpan="2" className="specSectionTitle">External Links</td>
                </tr>
              }
              {
                !cert.externalSystemLinks ? '' :
                  cert.externalSystemLinks.map((externalLink, i) => 
                  <>
                      <tr>
                          <td>ID</td>
                          <td>{externalLink.externalId}</td>
                      </tr>
                      <tr>
                          <td>System</td>
                          <td>{externalLink.externalSystem}</td>
                      </tr>
                      <tr>
                          <td>Name</td>
                          <td>{externalLink.externalName}</td>
                      </tr>
                    </>
                  )
              }
              <tr>
                <td colSpan="2" className="specSectionTitle">Spec Validations</td>
              </tr>
              <tr>
                <td>Html Spec Version</td>
                <td>{cert.validateClientHtmlSpecVersion}</td>
              </tr>
              
                {
                  cert.validateSpecChecks !== undefined?
                    cert.validateSpecChecks.map((check, i) => 
                      <tr>
                          <td>{check.label}</td>
                          <td className="checksInfo">
                            {check.passed? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}
                            <i className= {check.passed?'passedText':'noPassedText'}>{check.condition? `Condition: ${check.condition}`:''}</i>
                          </td>
                      </tr>
                    ):
                  <td colSpan="2">No data</td>
                }
                {
                  !cert.deployedBy ? "" :
                  <tr>
                    <td>Deploy By</td>
                    <td>{cert.deployedBy}</td>
                  </tr>
                }
                {
                  !cert.deployTime ? "" :
                  <tr>
                    <td>Deploy Time</td>
                    <td>{cert.deployTime}</td>
                  </tr>
                }
              <tr>
                <td colSpan="2" className="specSectionTitle">Media Services</td>
              </tr>
              <tr>
              <td>{statusText}</td>
                <td>{getMsDeployedVerifiedValue()? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}</td>
              </tr>
              <tr>
                <td colSpan="2"><b>MS Environments:</b></td>
              </tr>
              {/* INT */}
              <tr>
                <td colSpan="2"><b>Int ({cert.mediaServicesDeployedIntServerUrl})</b></td>
              </tr>
              <tr>
                <td>{statusText}</td>
                <td>{cert.mediaServicesDeployedIntStatus? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}</td>
              </tr>
              <tr>
                <td>Details</td>
                <td>{cert.mediaServicesDeployedIntDetails}</td>
              </tr>
              {/* TEST */}
              <tr>
                <td colSpan="2"><b>Test ({cert.mediaServicesDeployedTestServerUrl})</b></td>
              </tr>
              <tr>
                <td>{statusText}</td>
                <td>{cert.mediaServicesDeployedTestStatus? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}</td>
              </tr>
              <tr>
                <td>Details</td>
                <td>{cert.mediaServicesDeployedTestDetails}</td>
              </tr>
              {/* STAGE */}
              <tr>
                <td colSpan="2"><b>Stage ({cert.mediaServicesDeployedStageServerUrl})</b></td>
              </tr>
              <tr>
                <td>{statusText}</td>
                <td>{cert.mediaServicesDeployedStageStatus? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}</td>
              </tr>
              <tr>
                <td>Details</td>
                <td>{cert.mediaServicesDeployedStageDetails}</td>
              </tr>
              {/* STAGE */}
              <tr>
                <td colSpan="2"><b>Live ({cert.mediaServicesDeployedLiveServerUrl})</b></td>
              </tr>
              <tr>
                <td>{statusText}</td>
                <td>{cert.mediaServicesDeployedLiveStatus? <img src={verifiedImg} alt="Yes"/> : <img src={notVerifiedImg} alt="No"/>}</td>
              </tr>
              <tr>
                <td>Details</td>
                <td>{cert.mediaServicesDeployedLiveDetails}</td>
              </tr>
            </>
          }
        </tbody>
      </table>
    </StyledCertDetailsTable>
  );
}