import styled from "styled-components";

export const StyledCertTable = styled.div`
margin-bottom: 40px;
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
}

th {
  background: black;
  color: white;
  text-align: left;
  padding: 10px;
  cursor: pointer;

  img{
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
}

.shortCol{ 
  width: 100px;
}

.midCol{
  width: 180px;
}

.longCol{
  width: 250px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

tr:hover {
  background-color: #ddd;
}

.pagination-container{
  display: flex;
  flex-direction: row-reverse;
}
.pagination {
  ul  {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;

    a {
      display: block;
      text-align: center;
      padding: 10px;
      text-decoration: none;
      border: 1px solid black;

      :hover {
        background-color: black;
        color: white;
      }
    }

    :first-child {
      a{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    :last-child {
      a{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
`;

export const StyledNoCertificatesRown = styled.td`
  padding-top: 5px;
  text-align: center;
`;