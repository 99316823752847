import React, { useState, useEffect } from 'react';
import { StyledRow } from './certificate-table-row.styles';
import moment from 'moment';
import { calculateTimeLeft } from '../../../helpers/dateOperations';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {alertNotification} from '../../../helpers/notifications';
import { getRevokedCert, getRedeployCert} from '../../../services/pacmanClient';
//Images/icons
import openViewInTabIcon from '../../../images/open-view-icon.png'
import clipboardIcon from '../../../images/clipboard-icon.png';
import verifiedImg from '../../../images/verified.png';
import notVerifiedImg from '../../../images/not-verified.png';
import revokedCertImg from '../../../images/revokedFile.png';
import reDeployCertImg from '../../../images/reDeploy.png';


export const CertificateTableRow = ({certificate, showVerified, refreshCertificates, isManipulationAuth, isRootCert}) => {
  const today = moment(new Date());
  const futureDate = moment(today).add(3, 'M');
  const alert = futureDate.diff(moment(certificate.validTo)) > 0

  function refreshPage() {
    refreshCertificates();
  }

  const revokeCerts = async () => {

    const response = await getRevokedCert(certificate.shaDigest);

    if(response.data.result === true){
      alertNotification('success', response.data.details, 5000);
    }
    else if (response.data.result === false){
      alertNotification('warning', response.data.details, 7000);
    }else{
      alertNotification('error', response.data.details, 7000);
    }

    refreshPage();
  }

  const reDeployCerts = async () => {

    const response = await getRedeployCert(certificate.shaDigest);

    if(response.data.result === true){
      alertNotification('success', response.data.details, 5000);
    }
    else if (response.data.result === false){
      alertNotification('warning', response.data.details, 7000);
    }else{
      alertNotification('error', response.data.details, 7000);
    }

    refreshPage();
  }

  const copyNotification = () => {
    alertNotification('info', 'Copied!', 3000);
  }

  return(
    <StyledRow>
      <td>
        {certificate.serialNumber}  
        <CopyToClipboard  text={certificate.serialNumber}>
          <img className="copyImg" src={clipboardIcon} onClick={copyNotification}/>
        </CopyToClipboard>
      </td>
      <td>{certificate.subject}</td>
      {!isRootCert ?<td>{certificate.issuerCN}</td> :''}
      {isRootCert?<td>{certificate.deployed ? <img src={verifiedImg} alt="Yes"  className="verifiedImg"/> : <img src={notVerifiedImg} alt="No" className="noVerifiedImg"/>}</td>: null}
      {showVerified?<td>{certificate.verified ? <img src={verifiedImg} alt="Yes"  className="verifiedImg"/> : <img src={notVerifiedImg} alt="No" className="noVerifiedImg"/>}</td>:null}
      <td>{certificate.specValidation ? <img src={verifiedImg} alt="Yes"  className="verifiedImg"/> : <img src={notVerifiedImg} alt="No" className="noVerifiedImg"/>}</td>
      <td>{certificate.externalSystemIDs}</td>
      <td>{`${moment(certificate.validFrom).format('DD/MM/YYYY')}`}</td>
      <td>{`${moment(certificate.validTo).format('DD/MM/YYYY')}`}</td>
      <td className={alert?'red-row':''}>{calculateTimeLeft(new Date(), certificate.validTo)}</td>
      <td><a href={'/details/' + certificate.shaDigest}><img src={openViewInTabIcon} className="detailsImg" alt="view"/></a></td>
      {
        isRootCert && isManipulationAuth?
          <td>
            <img src={revokedCertImg} className="revokedCertImg" alt="revoke" onClick={revokeCerts}/>
          </td>
        : null }
        {isRootCert && isManipulationAuth?
          <td>
            <img src={reDeployCertImg} className="reDeployCertImg" alt="re-deploy" onClick={reDeployCerts}/>
          </td>
        : null
      }
    </StyledRow>
  );
}