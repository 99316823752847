import styled from "styled-components";

export const StyledCertDeploy = styled.div`
    h1 {
      margin-left: 15%;
    }

    .title {
      font-size: 2rem;
      text-align: center !important;
      margin-top: 3%;
      color: black;
      font-weight: bold;
    }
    
    .content {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .errors {
      padding: 20px;
      background-color: #ddd;
      margin: 20px;
      width: 600px;
      border: 2px solid #484848;
    }

    .deployBtn {
      border: 0;
      border-radius: 0.25rem;
      background: #1E88E5;
      color: white;
      font-family: system-ui, sans-serif;
      font-size: 1.2rem;
      font-weight:bold;
      line-height: 1.2;
      white-space: nowrap;
      text-decoration: none;
      padding: 0.25rem 0.5rem;
      margin: 0.25rem;
      cursor: pointer;
      margin-top: 20px;
      width: 300px;
      
      :disabled{
        background: rgb(221, 221, 221);
      }
    }
  }
`;