import React, { useState, useEffect } from 'react';
import { StyledCertTable, StyledNoCertificatesRown } from './certificate-table.styles';
import { TableHeading } from '../../atoms/table-heading';
import { CertificateTableRow }  from '../../atoms/certs-table-row';
import { 
  sortArrayByStringColumn, 
  sortArrayByDateColumn} 
from '../../../helpers/sortFunctions';
import { Paginator } from '../../atoms/paginator';
import sortIcon  from '../../../images/sort-icon.png';


const pageArray = (array, currentPage, pageSize) => {
  const offset = (currentPage - 1) * pageSize;
  return array.slice(offset).slice(0, pageSize);
}

export const CertificateTable = ({title, certificates, showVerified, refreshCertificates, isManipulationAuth, isRootCert}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pagedCertificates, setPagedCertificates] = useState([]);
  const [filteredCertificates, setFilteredCertificates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  useEffect(() => {
    if(!isFiltered){
      setPagedCertificates(pageArray(certificates, currentPage, pageSize));
    } else {
      setPagedCertificates(pageArray(filteredCertificates, currentPage, pageSize));
    }
    
  }, [currentPage, pageSize]);

  useEffect(() => {
    setPagedCertificates(pageArray(certificates, currentPage, pageSize));
  }, [certificates]);

  useEffect(() => {
    if(searchTerm){
      if(searchTerm.trim().length > 0){

        if(searchTerm === "pacman"){
          window.open('https://pacmangame.networking.certification.bbctvapps.co.uk/', '_blank');
          setPagedCertificates(pageArray(certificates, currentPage, pageSize));
        }else{


            // Filter based on the search term and set is filtered to true
            const filteredCerts = certificates.filter((cert) => 
              cert.serialNumber.toLowerCase().includes(searchTerm.toLowerCase().trim())
              || cert.subject.toLowerCase().includes(searchTerm.toLowerCase().trim())
              || cert.externalSystemIDs.toLowerCase().includes(searchTerm.toLowerCase().trim())
              || ((cert.issuerCN) && (cert.issuerCN.toLowerCase().includes(searchTerm.toLowerCase().trim())))
            );

            setIsFiltered(true);
            // Reset pagination parameters
            setCurrentPage(1);
            setFilteredCertificates(filteredCerts);
            setPagedCertificates(pageArray(filteredCerts, currentPage, pageSize));
        }
      }
    } else {
      setIsFiltered(false);
      // Reset pagination parameters
      setCurrentPage(1);
      setPagedCertificates(pageArray(certificates, currentPage, pageSize));
    }
  }, [searchTerm]);

  const sortCertificatesByStringColumn = (columnName) => {
    if(isFiltered){
      const sortedFilteredArray = sortArrayByStringColumn(filteredCertificates, columnName);
      setPagedCertificates(pageArray(sortedFilteredArray, currentPage, pageSize));
    } else {
      const sortedArray = sortArrayByStringColumn(certificates, columnName);
      setPagedCertificates(pageArray(sortedArray, currentPage, pageSize));
    }
  }

  const sortCertificatesByDateColumn = (columnName) => {
    if(isFiltered){
      const sortedFilteredArray = sortArrayByDateColumn(filteredCertificates, columnName);
      setPagedCertificates(pageArray(sortedFilteredArray, currentPage, pageSize));
    } else {
      const sortedArray = sortArrayByDateColumn(certificates, columnName);
      setPagedCertificates(pageArray(sortedArray, currentPage, pageSize));
    }
  }

  /**
   * Calculates the number of column of the certificates table
   */
  const calculateColSpan = () => {
    let baseColSpan = 9;

    if (showVerified) {
      baseColSpan += 1;
    }

    if (isRootCert && isManipulationAuth) {
      baseColSpan += 2;
    }

    return baseColSpan;
  }

  return(
    <StyledCertTable>
      <TableHeading title={title} setSearchTerm={setSearchTerm}/>
      <table>
        <thead>
          <tr>
            <th className="longCol" onClick={() => sortCertificatesByStringColumn('serialNumber')}>Serial Number <img src={sortIcon} alt=""/></th>
            <th className="longCol" onClick={() => sortCertificatesByStringColumn('subject')}>Subject (CN | O) <img src={sortIcon} alt=""/></th>
            {!isRootCert?<th>Issuer</th>:null}
            {isRootCert?<th>Deployed</th>:null}
            {showVerified?<th>Verified</th>:null}
            <th>Spec Validation</th>
            <th>External IDs</th>
            <th className="midCol" onClick={() => sortCertificatesByDateColumn('validFrom')}>Valid From <img src={sortIcon} alt=""/></th>
            <th className="midCol" onClick={() => sortCertificatesByDateColumn('validTo')}>Valid To <img src={sortIcon} alt=""/></th>
            <th className="longCol" >Time Left</th>
            <th className="shortCol">Details</th>
            {isRootCert && isManipulationAuth?<th className="shortCol" >Revoked</th>:null}
            {isRootCert && isManipulationAuth?<th className="shortCol" >Re-Deploy</th>:null}
          </tr>
        </thead>
        <tbody>
          {pagedCertificates.length === 0 && (
            <tr>
              <StyledNoCertificatesRown colSpan={calculateColSpan()}>No certificates found</StyledNoCertificatesRown>
            </tr>
          )}
          {pagedCertificates.map((cert, i) => 
            <CertificateTableRow 
              key={i} 
              certificate={cert} 
              showVerified={showVerified} 
              refreshCertificates={refreshCertificates}
              isManipulationAuth={isManipulationAuth}
              isRootCert={isRootCert}
            />
          )}
        </tbody>
      </table>
      <Paginator 
        arrayToPage={isFiltered? filteredCertificates : certificates} 
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        pageSizeOptions= {[5,10,20,0]}
        setPageSize={setPageSize}
        setPagedCertificates={setPagedCertificates}
      />
    </StyledCertTable>
  );
}