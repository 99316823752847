import React, { useState, useEffect } from 'react';
import { StyledPaginator } from './paginator.styles';
import { PageItem } from './page-item';

const pageArray = (array, currentPage, pageSize) => {
  const offset = (currentPage - 1) * pageSize;
  return array.slice(offset).slice(0, pageSize);
}

export const Paginator = ({arrayToPage, setCurrentPage, currentPage, pageSize, pageSizeOptions, setPageSize, setPagedCertificates}) => {
  const [numberOfPages, setNumberOfPages] = useState(0);

  const pagingItems = [];

  let numberOfPageItems = 5;
  let startingPage = currentPage;

  if(numberOfPages < numberOfPageItems) {
    numberOfPageItems = numberOfPages;
  }

  const roundedNumberOfPages = Math.ceil(numberOfPages);
  if(currentPage <= 2) {
    startingPage = 0;
  } else if(currentPage === roundedNumberOfPages) {
    startingPage = currentPage - 5;
  } else if(currentPage === roundedNumberOfPages - 1) {
    startingPage = currentPage - 4;
  } else {
    startingPage = currentPage - 3;
  }

  let endingPage = startingPage + numberOfPageItems;

  if(endingPage > numberOfPages){
    endingPage = numberOfPages;
  }

  for (let i = startingPage; i < endingPage; i++) {
    pagingItems.push(<PageItem key={i} index={i} setCurrentPage={setCurrentPage} currentPage={currentPage}/>)
  }

  useEffect(() => {
    setNumberOfPages(arrayToPage.length/pageSize);
  }, [arrayToPage, pageSize]);
 
  const handleChange =  event => { 
    const selected = event.target.value;
    if(+selected === 0){
      setPageSize(arrayToPage.length);
    }else{
      setPageSize(+selected);
    }
  };

  return(
    <StyledPaginator>
      <div className="pagination">
        <div className="selectDiv">
          <p className="selectText">Show</p>
          <select className="itemsSelect" onChange={handleChange} value={pageSize === arrayToPage.length ? 0 : pageSize} >
            {pageSizeOptions.map((item) => 
              <option value={item}>{item === 0 ? 'All' : item}</option>
            )}
          </select>
        </div>
        <ul>
          <li className={currentPage == 1? 'not-active': ''}> <a onClick={() => setCurrentPage(currentPage-1)}>{'<<'}</a></li>
            {pagingItems.map((item) => item)}
          <li className={currentPage < numberOfPages ? '': 'not-active'}> <a onClick={() => setCurrentPage(currentPage+1)}>{'>>'}</a></li>
        </ul>
      </div>
    </StyledPaginator>
  );
}