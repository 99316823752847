import styled from "styled-components";

export const StyledTableHeading = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;

  h2 {
    display: block;
  }
  .spacer {
    flex-grow: 1;
  }
  input[type=text] {
    display: block;
    border-radius: 10px;
    width: 200px;
    height: 25px;
  }
`;