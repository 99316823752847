import styled from "styled-components";

export const StyledMenu = styled.div`
  background-color: black;
  &.hide{
    display:none;
  }
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      color: white;
      font-size: 1.75em;
      padding: 10px;
      a{
        text-decoration: none;
        color:inherit;
        display: block;
        width: 100%;
        height: 100%;
      }
      :hover {
        background-color: white;
        color: black;
        border-bottom: 1px solid black;
        cursor: pointer;
      }
    }
  }
`;