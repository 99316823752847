import moment from 'moment';

const sortByStringColumn = (columnName, a,b) => (
  a[columnName] > b[columnName]) ? 1 
  : ((b[columnName] > a[columnName]) ? -1 : 0
);

const sortByStringColumnReverseOrder = (columnName, a,b) => (
  a[columnName] < b[columnName]) ? 1 
  : ((b[columnName] < a[columnName]) ? -1 : 0
);

const sortByDateColumn = (columnName, a,b) => (
  moment(a[columnName]).isSameOrAfter(b[columnName], 'day') ? 1 
  : -1
);

function compare(arr1,arr2){
  if(!arr1  || !arr2) return
   let result;
  arr1.forEach((e1,i)=>arr2.forEach(e2=>{
      if(e1.length > 1 && e2.length){
        result = compare(e1,e2);
      }else if(e1 !== e2 ){
        result = false
      }else{
        result = true
      }
    })
  )
 return result
}

export const sortArrayByStringColumn = (array, columnName) => {
  const originArray = [...array];
  let sortedArray = array.sort((a,b) => 
    sortByStringColumn(columnName, a, b)
  );

  if(compare(originArray,sortedArray) === true){
    sortedArray = array.sort((a,b) => 
      sortByStringColumnReverseOrder(columnName, a, b)
    );
  }
  return [...sortedArray];
}

export const sortArrayByDateColumn = (array, columnName) => {
  const originArray = [...array];
  let sortedArray = array.sort((a,b) => 
    sortByDateColumn(columnName, a, b)
  );
  if(compare(originArray,sortedArray) === true){
    sortedArray = array.sort((a,b) => 
      sortByStringColumnReverseOrder(columnName, a, b)
    );
  }
  return [...sortedArray];
}