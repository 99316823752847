import React, { useState } from 'react'
import { StyledCertDeploy } from './cert-deploy.styles';
import { postCertToDeploy, validateCaCert } from '../../../services/pacmanClient';

export const CertDeploy = () => {
  const [disableBtn, setDisableBtn] = useState(true);
  const [info, setInfo] = useState([]);

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (file.size > 20000) {
      setInfo(['File size can not be larger than 20kb']);
      return;
    }

    setDisableBtn(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableBtn(true);

    const form = e.target;
    const formData = new FormData(form);

    setInfo(['Please wait...']);
    validateCaCert(formData)
      .then(response => {
        if (response.data.valid === false) {
          const errors = [
            'Certificate validation failed, please notify the partner to fix following issues:',
            ...response.data.results.filter(r => r.state === false).map(r => r.label)
          ]
          setInfo(errors);
          return;
        }

        if (response.data.error) {
          const errors = [
            'Certificate validation failed, please notify the partner to fix following issues:',
            response.data.error
          ]
          setInfo(errors);
          return;
        }

        postCertToDeploy(formData)
          .then(response => {
            if (response.data.details.result === false) {
              const errors = [
                'Certificate deployment failed:',
                ...response.data.details
              ];
              setInfo(errors);
              return;
            }

            const msgs = [
              'Certificate has been deployed successfully',
              response.data.details,
              'SHA256 Digest: ' + response.data.sha256DigestHex
            ];
            setInfo(msgs);
          })
          .catch(response => {
            const errors = [
              'Certificate deployment failed:',
              ...response.data.details
            ];
            setInfo(errors);
          });

        setDisableBtn(false);
      })
      .catch(response => {
        if (response.error) {
          const errors = [
            'Certificate validation failed, please notify the partner to fix following issues:',
            response.error
          ]
          setInfo(errors);
          return;
        }
        setInfo(['Unknown Error']);
      });
  }

  return (
    <StyledCertDeploy>
      <p className="title">Select a certificate to deploy</p>
      <form method="post" onSubmit={handleSubmit} className="content">
        <input type="file" onInput={handleChange} name='cert' />
        {info.length ?
          <div className='errors' >
            <ul>
              {info.map((value, id) => {
                return (<li id={id}>{value}</li>)
              })}
            </ul>
          </div>
          : <></>
        }
        <button type="submit" className="deployBtn" disabled={disableBtn}>
          Deploy Certificate
        </button>
      </form>
    </StyledCertDeploy>
  );
}
