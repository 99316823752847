import styled from "styled-components";

export const StyledBurgerIcon = styled.div`
  width: 45px;
  height: 45px;
  margin-right: 50px;
  background-color: black;
  border-radius: 10px;
  padding: 5px;
  align-items: center;

  .burger {
    height: 100%;
    margin: 0 auto;
  }
`;