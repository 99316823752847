import styled from "styled-components";

export const StyledRow = styled.tr`
  td {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;

    &.red-row {
      background-color: red;
      color:white;
      font-weight: bold;
    }
    .copyImg {
      width: 20px;
      height: 20px;
      padding-left:5px;
      :hover{
        cursor:pointer;
      }
    }
    .revokedCertImg, .reDeployCertImg{
      :hover{
        cursor:pointer;
      }
    }
    .detailsImg, .verifiedImg, .noVerifiedImg, .revokedCertImg, .reDeployCertImg{
      width: 30px;
      height: 30px;
    }
  }
`;