
import moment from 'moment';
import { 
  getOverviewData, 
  getDetailsData, 
  getManipulationAuthorised, 
  getCertReadOnlyAuthorisedData,
  getDeployedCertificatesData,
  getMsDeployedData
} from './pacmanClient';

const mockCertificateDetails = 
  {
    verified: true,
    serialNumber: '1abc',
    subjectCN: 'karina Garces',
    subjectOrganization: 'BBC',
    subjectCountry: 'England',
    issuerCN: 'BBC Staff',
    issuerOrganization: 'BBC',
    issuerCountry: 'England',
    validNotBefore: '03/01/2019',
    validNotAfter: '03/01/2044'
  };

const mockIntermediateCertDetails =   {
  verified: true,
  serialNumber: '1abc',
  subjectCN: 'BBC Staff',
  subjectOrganization: 'BBC',
  subjectCountry: 'England',
  issuerCN: 'BBC Root',
  issuerOrganization: 'BBC',
  issuerCountry: 'England',
  validNotBefore: '03/01/2019',
  validNotAfter: '03/01/2044'
};

const mockRootCertificates = [
  {
    serialNumber: '2abc',
    subject: 'Netcast LG',
    deployed: true,
    validFrom: new Date('03/01/2019'),
    validTo: new Date('03/01/2025')
  },
  {
    serialNumber: '3abc',
    subject: 'Sony LG',
    deployed: true,
    validFrom: new Date('06/09/2010'),
    validTo: new Date('06/08/2020')
  },
  {
    serialNumber: '1abc',
    subject: 'Samsung',
    deployed: true,
    validFrom: new Date('07/10/2013'),
    validTo: new Date('03/20/2022')
  }
];

const mockClientCertificates = [
  {
    serialNumber: '1abc',
    subject: 'Netcast LG',
    deployed: true,
    validFrom: new Date('03/01/2019'),
    validTo: new Date('03/01/2044'),
    verified: true
  },
  {
    serialNumber: '2abc',
    subject: 'Sony',
    deployed: true,
    validFrom: new Date('06/09/2010'),
    validTo: new Date('06/08/2020'),
    verified: true
  },
  {
    serialNumber: '3abc',
    subject: 'Samsung',
    deployed: true,
    validFrom: new Date('07/10/2013'),
    validTo: new Date('03/20/2039'),
    verified: true
  }
];

const convertRawDataInCertObj = (raw) => {
  const validFrom = new Date(raw.validityPeriod.notBefore);
  const validTo = new Date(raw.validityPeriod.notAfter);
  let isCA = !!raw.registeredAsCa;
  let externalIDs = [];
  let externalIdsStr = ""

  
  let subjectCombined = "";

  // Combining the subject with the organisation name, if they are present.
  if(raw.subject.CN !== undefined){
    if(raw.subject.O !== undefined){
      subjectCombined = `${raw.subject.CN} | ${raw.subject.O}`;
    }else{
      subjectCombined = raw.subject.CN;
    }
  }else{
    subjectCombined = raw.subject.O;
  }

  if (raw.externalSystemLinks)  {
    if(raw.externalSystemLinks.length == 0){
      externalIdsStr = "N/A";
    }else{
      raw.externalSystemLinks.map((externalLink, i) => 
        externalIDs.push(externalLink.externalId)
      )
      externalIdsStr = externalIDs.join("|");
    }
  } else{
    externalIdsStr = "N/A";
  }
  


  const cert = {
    serialNumber: raw.serialNr,
    shaDigest: raw.sha256DigestHex,
    ca: isCA,
    subject: subjectCombined,
    issuerCN: raw.issuer.CN === undefined ? "" : raw.issuer.CN,
    deployed: raw.deployed === undefined ? "" : raw.deployed,
    validFrom: validFrom,
    validTo: validTo,
    specValidation: raw.specValidationResult.passed,
    verified: raw.verified ? raw.verified : false,
    registeredAsCa: raw.registeredAsCa,
    externalSystemIDs: externalIdsStr,
  }

  return cert;
}

const convertRawDetailInCertDetailObj = (raw, msDeployedInt, msDeployedTest, msDeployedStage, msDeployedLive) => { 
  const validFrom = new Date(raw.validityPeriod.notBefore);
  const validTo = new Date(raw.validityPeriod.notAfter);
  let isCA = !!raw.registeredAsCa;

  const intCerts = [];
  if(raw.intermediateCerts !== undefined){
    if(raw.intermediateCerts){
      raw.intermediateCerts.forEach(function(entry) {
        const inCert = convertRawDetailInCertDetailObj(entry, msDeployedInt, msDeployedTest, msDeployedStage, msDeployedLive);
        inCert.isInterCert = true;
        intCerts.push(inCert); 
      });
    }
  }

  const cert = {
    serialNumber: raw.serialNr,
    shaDigest: raw.sha256DigestHex,
    ca: isCA,
    subjectCN: raw.subject.CN !== undefined ? raw.subject.CN : raw.subject.O,
    subjectOrganization: raw.subject.O,
    subjectOrganizationUnit: raw.subject.OU,
    subjectCountry: raw.subject.C,
    subjectLocation: raw.subject.L,
    issuerCN: raw.issuer.CN,
    issuerOrganization: raw.issuer.O,
    issuerOrganizationUnit: raw.issuer.OU,
    issuerCountry: raw.issuer.C,
    issuerLocation: raw.issuer.L,
    validFrom: validFrom,
    validTo: validTo,
    verified: raw.verified ? raw.verified : false,
    intermediateCerts: intCerts,
    base64: raw.base64,
    validateClient: raw.specValidationResult ? raw.specValidationResult.passed : false,
    validateClientHtmlSpecVersion: raw.specValidationResult ? raw.specValidationResult.specVersion : '',
    validateSpecChecks: raw.specValidationResult ? raw.specValidationResult.checks : [],
    deployedBy: raw.deployedBy,
    deployTime: raw.deployedTimestamp ? moment(raw.deployedTimestamp).format("DD-MM-YYYY HH:mm:ss") : "",
    mediaServicesDeployedIntServerUrl: msDeployedInt.serverUrl,
    mediaServicesDeployedIntStatus: msDeployedInt.msDeployedData.result,
    mediaServicesDeployedIntDetails: msDeployedInt.msDeployedData.details,
    mediaServicesDeployedTestServerUrl: msDeployedTest.serverUrl,
    mediaServicesDeployedTestStatus: msDeployedTest.msDeployedData.result,
    mediaServicesDeployedTestDetails: msDeployedTest.msDeployedData.details,
    mediaServicesDeployedStageServerUrl: msDeployedStage.serverUrl,
    mediaServicesDeployedStageStatus: msDeployedStage.msDeployedData.result,
    mediaServicesDeployedStageDetails: msDeployedStage.msDeployedData.details,
    mediaServicesDeployedLiveServerUrl: msDeployedLive.serverUrl,
    mediaServicesDeployedLiveStatus: msDeployedLive.msDeployedData.result,
    mediaServicesDeployedLiveDetails: msDeployedLive.msDeployedData.details,
    publicKeyInfo: raw.publicKeyInfo,
    registeredAsCa: raw.registeredAsCa,
    isInterCert: false,
    associatedUserAgents: raw.associatedUserAgents,
    externalSystemLinks: raw.externalSystemLinks ? raw.externalSystemLinks : [],
  }

  return cert;
}



const getCertFilteredAndConverted = async (isCAVal) => {
  let rawData = await getOverviewData();
  let rawCertsData = rawData.data;

  const certs = rawCertsData.certificates.map(
    item => convertRawDataInCertObj(item)
  );

  let filteredCerts = certs.filter(function(item) {
    return item.registeredAsCa === isCAVal;
  });

  return filteredCerts;
}

const getRootCertificates = async () => {
  return await getCertFilteredAndConverted(true);
}

const getClientCertificates = async () => {
  return await getCertFilteredAndConverted(false);
}

export const getCertificates = async(type, isMocked) => {
  switch(type){
    case 'root':
      if(isMocked){
        return mockRootCertificates;
      } else {
        return await getRootCertificates();
      }
      break;
    case 'client':
      if(isMocked){
        return mockClientCertificates;
      } else {
        return await getClientCertificates();
      }
      break;
    default:
      return mockRootCertificates;
      break;
  }
}

export const getUserAuthorisedStatus = async () => {
  const authStatus = {
    isManipulationAuth: false,
    isCertReadOnly: false,
    isDeployedCaCerts: false,
    details:''
  };

  const manipulationAuth = await getManipulationAuthorised();
  const certReadOnlyAuth = await getCertReadOnlyAuthorisedData();
  const deployedCertsAuth = await getDeployedCertificatesData();
 
  if(manipulationAuth.data.result){
    authStatus.isManipulationAuth = manipulationAuth.data.result;
    authStatus.details = manipulationAuth.data.details;

  }else if (certReadOnlyAuth.data.result){
    authStatus.isCertReadOnly = certReadOnlyAuth.data.result;
    authStatus.details = certReadOnlyAuth.data.details;

  }else {
    authStatus.isDeployedCaCerts = deployedCertsAuth.data.result;
    authStatus.details = 'Deployed certs';
  }
  
  return authStatus;
}


const getServerUrl = (environment) => {

  switch(environment){
    case 'int':
      return 'ipsecure.int.bbc.co.uk';
      break;
    case 'test':
      return 'ipsecure.test.bbc.co.uk';
      break;
    case 'stage':
      return 'ipsecure.stage.bbc.co.uk';
      break;
    case 'live':
      return 'securegate.iplayer.bbc.co.uk';
      break;
    default:
      return 'ipsecure.test.bbc.co.uk';
      break;
  }
}
const getMsDeployedDataForEnvironment = async (environment, shaDigest) => {

  const deployedData = {
    serverUrl: '',
    msDeployedData: {}
  }

  deployedData.serverUrl = getServerUrl(environment);
  const msData = await getMsDeployedData(shaDigest, deployedData.serverUrl);

  deployedData.msDeployedData = msData.data;



  return deployedData;
}

export const getCertificateDetails = async (shaDigest) => {
  const detailsData = await getDetailsData(shaDigest);
  const msDeployedInt = await getMsDeployedDataForEnvironment('int', shaDigest);
  const msDeployedTest = await getMsDeployedDataForEnvironment('test', shaDigest);
  const msDeployedStage = await getMsDeployedDataForEnvironment('stage', shaDigest);
  const msDeployedLive = await getMsDeployedDataForEnvironment('live', shaDigest);

  if(detailsData.data.certificate) {
    const cert = detailsData.data.certificate;
    const parsedCert = convertRawDetailInCertDetailObj(cert, msDeployedInt, msDeployedTest, msDeployedStage, msDeployedLive);
    return parsedCert;
  }
  return null;
}
 
export const getCACertsNames = async () => {
  const deployedCerts = await getDeployedCertificatesData();
  return deployedCerts.data.caDistinguishedNames
}
