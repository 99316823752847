import styled from "styled-components";

export const StyledCertDetails = styled.div`
  padding: 0 20px;

  .goBackBtn{
    background: #000;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    padding: 5px 12px;
    margin-top: 10px;
    font-size: 20px;
    line-height: 19px;
    font-weight: 700;

    span {
      vertical-align: middle;
    }

    :hover{
      cursor: pointer;
      background: white;
      color: black;
    }

    .goBackImg{
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
`;