import React, { useState, useEffect } from 'react';
import { StyledCertDetails } from './cert-details.styles';
import { CertDetailsTable } from '../../molecules/cert-details-table';
import { getCertificateDetails } from '../../../services/certificates';
import { useHistory } from "react-router-dom";
import goBackImg from '../../../images/backIcon.png';
import LoadingOverlay from 'react-loading-overlay';
import PacmanLoader from 'react-spinners/PacmanLoader'

export const CertDetails = ({ match }) => {
  const shaDigest = match.params.shaDigest;
  const [certDetails, setCertDetails] = useState({});
  const [intermediateCerts, setIntermediateCerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  useEffect(() => {
    const loadDetails = async() => {
      const details = await getCertificateDetails(shaDigest);

      if(details){
        setCertDetails(details);
        setIntermediateCerts(details.intermediateCerts);
      }
    }
    loadDetails();
  }, []);

  useEffect(() => {
    if(Object.keys(certDetails).length === 0 && certDetails.constructor === Object){
      setLoading(true);
      document.body.style.overflow = "hidden";
    }else{
      setLoading(false);
      document.body.style.overflow = "scroll";
    }
  }, [certDetails]);

  function handleClick() {
    history.push("/");
  }

  const getTitleForLastCertInClientChain = (index) => {
    const certsLength = intermediateCerts.length;
    let title = '';
    if (certsLength === index + 1) {
      // last one
      title = 'Root CA'
    } else {
      // not last one
      title = 'Intermediate CA'
    }

    return title;
  }
  
  return(
    <StyledCertDetails>     
      <LoadingOverlay
        spinner={
          <PacmanLoader
            color={"#F8E71C"}
          />
        } 
        active={loading} // check below for more loaders
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgba(0,0,0,0.5)'
          }),
          wrapper: {
            overflow: loading ? 'hidden' : 'scroll'
          }
        }}
      /> 
      <button className="goBackBtn" type="button" onClick={handleClick}>
          <img src={goBackImg} className="goBackImg" alt="Go Back"/>
          <span>Back</span>
        </button>
        <CertDetailsTable
          title={certDetails.ca ? "Root Certificate" : "Client Certificate"}
          cert={certDetails}
        />

        {
          intermediateCerts.length > 0 &&
          intermediateCerts.map((cert, i) => 
            <CertDetailsTable 
              key={i} 
              title={getTitleForLastCertInClientChain(i)}
              cert={cert} 
            />
          )
        }
    </StyledCertDetails>
  );
}