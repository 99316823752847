import axios from 'axios';

export const getManipulationAuthorised = async () => {
  const response = await axios.get('/api/cert-manipulation-authorised')
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
}

// If the user has manipulation authiorised.
export const getOverviewData = async () => {
  const response = await axios.get('/api/overview')
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
}

/* If certificate of the client is BBC staff but he is not in the 
* list of authorised for manipulation.
*/
export const getCertReadOnlyAuthorisedData = async () => {
  const response = await axios.get('/api/cert-read-only-authorised')
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
}

// No certificates.
export const getDeployedCertificatesData = async () => {
  const response = await axios.get('/api/deployed-ca-certificates')
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
}

export const getDetailsData = async (shaDigest) => {
  const response = await axios.get(`/api/details/${shaDigest}`)
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
};

export const validateCaCert = async (formData) => {
  const response = await axios.post('/api/validate-ca-certificate', formData, {})
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
};

export const postCertToDeploy = async (formData) => {
  const response = await axios.post('/api/deploy-certificate/', formData, {})
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
};

export const getRevokedCert = async (shaDigest) => {
  const response = await axios.get(`/api/revoke-certificate/${shaDigest}`)
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
};

export const getRedeployCert = async (shaDigest) => {
  const response = await axios.get(`/api/redeploy-certificate/${shaDigest}`)
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
};

export const getValidateClient = async () => {
  const response = await axios.get('/api/validate-client')
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
};

export const getMsDeployedData = async (shaDigest, serverUrl) => {

  const serverPort = 443;

  const url = `/api/ms-deployed/${shaDigest}?serverUrl=${serverUrl}&serverPort=${serverPort}`

  const response = await axios.get(url)
  .catch(function (error) {
    if (error.response) {
      return error.response;
    }
  });
  return response;
};