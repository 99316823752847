import React from 'react';
import { StyledTableHeading } from './table-heading.styles';

export const TableHeading = ({title, setSearchTerm}) => {

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  }

  return(
    <StyledTableHeading>
      <h2>{title}</h2>
      <div className="spacer" />
      <input type="text" placeholder="Search ..." onChange={handleChange} />
    </StyledTableHeading>
  );
}