import styled from "styled-components";

export const StyledHeader = styled.div`
  padding: 0 20px;
  .header-container {
    display: flex;
    align-items: center;
    h1 {
      flex-grow: 1;
      display: block;
    }
  }
  hr {
    border-top: 5px solid black;
    border-left: 5px solid black;
    border-right: 5px solid black;
    margin-bottom: 0px;
    border-bottom: 0;
  }
`;