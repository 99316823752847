import styled from "styled-components";

export const StyledDeployedCerts = styled.div`
margin-bottom: 40px;
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 40px;
}

th {
  background: black;
  color: white;
  text-align: center;
  padding: 10px;
  cursor: pointer;

  img{
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
}

tr:nth-child(even) {
  background-color: #dddddd;
}

tr:hover {
  background-color: #ddd;
}

td {
  border-bottom: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.pagination-container{
  display: flex;
  flex-direction: row-reverse;
}
.pagination {
  ul  {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;

    a {
      display: block;
      text-align: center;
      padding: 10px;
      text-decoration: none;
      border: 1px solid black;

      :hover {
        background-color: black;
        color: white;
      }
    }

    :first-child {
      a{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    :last-child {
      a{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
`;