import React, { useState, useEffect } from 'react';
import { BurgerIcon } from '../../atoms/burger-icon';
import { Logo } from '../../atoms/logo';
import { StyledHeader } from './header.styles';
import { Menu } from '../../atoms/menu';
import { getUserAuthorisedStatus} from '../../../services/certificates';

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [authStatus, setAuthStatus] = useState({});

  useEffect(() => {
    async function fetchData() {
      const status = await getUserAuthorisedStatus();
      setAuthStatus(status); 
    }
    fetchData();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return(
    <StyledHeader>
      <div className="header-container">
        <BurgerIcon isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        <h1>Partner Certificate Manager</h1>
        <Logo />
      </div>
      <hr/>
      <Menu isMenuOpen={isMenuOpen} manipulationStatus={authStatus.isManipulationAuth}/>
    </StyledHeader>
  );
}