import React from 'react';
import { StyledMenu } from './menu.styles';
import guidePdf from '../../../Documents/PartnerCertificateManager.pdf';

export const Menu = ({isMenuOpen, manipulationStatus}) => {
  return(
    <StyledMenu className={`${isMenuOpen ? '' : 'hide'}`}>
      <ul>
        <li><a href="/">HOME</a></li>
        { manipulationStatus ?
          <li><a href="/deploy">DEPLOY CERTIFICATE</a></li> :
          null
        }
        <li><a href="https://certification.bbctvapps.co.uk/docs/faq/tls/" target="_blank">DOCUMENTATION</a></li>
        <li><a href={guidePdf} target="_blank">GUIDE</a></li>
        <li><a href="https://certification.bbctvapps.co.uk/jira/servicedesk/customer/portal/4/user/login/" target="_blank">CONTACT</a></li>
      </ul>

    </StyledMenu>
  );
}