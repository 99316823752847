import React, { useState, useEffect} from 'react';
import { StyledDeployedCerts } from './deployed-ca-certificates-view.styles';
import { TableHeading } from '../../atoms/table-heading';
import { Paginator } from '../../atoms/paginator';
import { CACertsDistinguishedNamesRow } from '../../atoms/ca-deployed-names-table-row'

const pageArray = (array, currentPage, pageSize) => {
  const offset = (currentPage - 1) * pageSize;
  return array.slice(offset).slice(0, pageSize);
}

export const DeployedCaCertificatesView =  ({caCertsNames}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [pagedCaCertsDistinguishedNames, setPagedCaCertsDistinguishedNames] = useState([]);
  const [filteredCertificatesNames, setFilteredCertificatesNames] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);


  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  useEffect(() => {
    if(!isFiltered){
      setPagedCaCertsDistinguishedNames(pageArray(caCertsNames, currentPage, pageSize));
    } else {
      setPagedCaCertsDistinguishedNames(pageArray(filteredCertificatesNames, currentPage, pageSize));
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    setPagedCaCertsDistinguishedNames(pageArray(caCertsNames, currentPage, pageSize));
  }, [caCertsNames, pageSize]);

  useEffect(() => {
    if(searchTerm){
      if(searchTerm.trim().length > 0){
        // Filter based on the search term and set is filtered to true
        const filteredCaDistinguishedNames = caCertsNames.filter((cert) => 
  
        (cert.CN && cert.CN.toLowerCase().includes(searchTerm.toLowerCase().trim()))
          || (cert.C && cert.C.toLowerCase().includes(searchTerm.toLowerCase().trim()))
          || (cert.L && cert.L.toLowerCase().includes(searchTerm.toLowerCase().trim()))
          || (cert.ST && cert.ST.toLowerCase().includes(searchTerm.toLowerCase().trim()))
          || (cert.O && cert.O.toLowerCase().includes(searchTerm.toLowerCase().trim()))
          || (cert.OU && cert.OU.toLowerCase().includes(searchTerm.toLowerCase().trim()))
        );
        setIsFiltered(true);
        // Reset pagination parameters
        setCurrentPage(1);
        setFilteredCertificatesNames(filteredCaDistinguishedNames);
        setPagedCaCertsDistinguishedNames(pageArray(filteredCaDistinguishedNames, currentPage, pageSize));
      }
    } else {
      setIsFiltered(false);
      // Reset pagination parameters
      setCurrentPage(1);
      setPagedCaCertsDistinguishedNames(pageArray(caCertsNames, currentPage, pageSize));
    }
  }, [searchTerm]);

  return(
    <StyledDeployedCerts>
      <TableHeading title="" setSearchTerm={setSearchTerm}/>
      <table>
        <thead>
          <tr>
            <th>Common Name (CN)</th>
            <th>Organisation (O)</th>
            <th>Organisation Unit (OU)</th>
            <th>Country (C)</th>
            <th>Location (L)</th>
            <th>State (ST)</th>
          </tr>
        </thead>
        <tbody>
        {
          pagedCaCertsDistinguishedNames === undefined || pagedCaCertsDistinguishedNames.length <= 0 ? '' :
          pagedCaCertsDistinguishedNames.map((caDeployedName, i) => 
            <CACertsDistinguishedNamesRow 
              key={i}
              caDeployedName={caDeployedName}
            />
          )}
        </tbody>
      </table>
      <Paginator 
        arrayToPage={isFiltered? filteredCertificatesNames : caCertsNames} 
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        pageSize={pageSize}
        pageSizeOptions= {['5','10','20','All']}
        setPageSize={setPageSize}
      />
    </StyledDeployedCerts>
  );
}