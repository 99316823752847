import React, { useState, useEffect } from 'react';
import { StyledCertOverview } from './cert-overview.styles';
import { CertificateTable } from '../../molecules/certificate-table';
import { DeployedCaCertificatesView } from '../../molecules/deployed-ca-certificates-view';
import { getCertificates, getUserAuthorisedStatus, getCACertsNames} from '../../../services/certificates';

export const CertOverview = () => {
  const [rootCertificates, setRootCertificates] = useState([]);
  const [clientCertificates, setClientCertificates] = useState([]);
  const [caCertsNames, setcaCertsNames] = useState([]);
  const [authStatus, setAuthStatus] = useState({});

  useEffect(() => {
    async function fetchData() {
      const status = await getUserAuthorisedStatus();
      setAuthStatus(status);
      if((status.isManipulationAuth) || (status.isCertReadOnly)){
        setRootCertificates(await getCertificates('root', false));
        setClientCertificates(await getCertificates('client', false));
      } else{
        setcaCertsNames(await getCACertsNames());
      }  
    }
    fetchData();
  }, []);
  
  const refreshRootCertificates = async () => {
    setRootCertificates(await getCertificates('root', false));
  }

  const refreshClientCertificates = async () => {
    setRootCertificates(await getCertificates('root', false));
  }
  

  return(
    <StyledCertOverview>
      {
        (authStatus.isManipulationAuth) || (authStatus.isCertReadOnly)? 
          <>
            <CertificateTable
              title="Root Certificates"
              certificates={rootCertificates}
              showVerified={false}
              refreshCertificates={refreshRootCertificates}
              isManipulationAuth={authStatus.isManipulationAuth}
              isRootCert = {true}
            />
            <CertificateTable
              title="Client Certificates"
              certificates={clientCertificates}
              showVerified={true}
              refreshCertificates={refreshClientCertificates}
              isManipulationAuth={authStatus.isManipulationAuth}
              isRootCert = {false}
            />
          </>
        :
        <DeployedCaCertificatesView caCertsNames={caCertsNames}/>
      }
      
    </StyledCertOverview>
  );
}