import * as React from 'react';
import { StyledBurgerIcon } from './burger-icon.styles';
// React Component
import Burger from '@animated-burgers/burger-rotate' 
// don't forget the styles
import '@animated-burgers/burger-rotate/dist/styles.css'

export const BurgerIcon = ({isMenuOpen, toggleMenu}) => {
  return(
    <StyledBurgerIcon>
      <Burger isOpen={ isMenuOpen } onClick={toggleMenu}/>
    </StyledBurgerIcon>
  );
}