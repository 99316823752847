import styled from "styled-components";

export const StyledCertDetailsTable = styled.div`
table {
  border-collapse: collapse;
  width: 70%;
  margin-bottom: 50px;

  td, th {
    padding: 8px;
    text-align: left;
  }

  td{
    img {
      width: 25px;
      height: 25px;
    }
  }

  tr:nth-child(even){
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: black;
    color: white;
  }

  .base64Box{
    margin: 0px;
    width: 100%;
    height: 200px;
    background: #EBECF0;
  }
  .copyImg:hover{
    cursor:pointer;
  }
  .checksInfo {
    i{
      margin-left: 10%;
    }

    .passedText{
      color:darkgreen;
    }

    .noPassedText{
      color: red;
    }
  }

  .specSectionTitle{
    font-weight: bold;
    font-size: 20px;
  }

  .notification-container {
    width: 500px !important;
  }
}
`;