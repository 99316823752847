import * as React from 'react';
import { StyledLogo } from './logo.styles';
import bbcLogo from '../../../images/Master_BBC_Logo.jpg'

export const Logo = () => {
  return(
    <StyledLogo>
      <a href="/" >
        <img src={bbcLogo} alt="Logo" />
      </a>
    </StyledLogo>
  );
}