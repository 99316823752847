import styled from "styled-components";

export const StyledPaginator = styled.div`
display: flex;
flex-direction: row-reverse;

.pagination {
  display: flex;
  text-align: center;
  font-size: 16px;
  margin: 5px;

  ul  {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding-left: 30px;
  }

  li {
    float: left;

    a {
      display: block;
      text-align: center;
      padding: 10px;
      text-decoration: none;
      border: 1px solid black;

      :hover {
        background-color: black;
        color: white;
      }
    }

    :first-child {
      a{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    :last-child {
      a{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .not-active {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: #989898;
    background-color: #E0E0E0;
  }

  .activePage{
    background-color: black;
    color: white;
    font-weight: bold;
  }

  .selectDiv{
    display: flex;
  }

  .itemsSelect{
    font-weight: 400;
    background-color: white;
    border: thin solid #0b0b0b;
    border-radius: 5px;
    display: inline-block;
    font: inherit;
    line-height: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    margin: 0;
    margin-bottom: 0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 10px;
    text-align: center;
    width: 96px;

    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      radial-gradient(#ddd 70%, transparent 72%);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - .5em) .5em;
    background-size:
      5px 5px,
      5px 5px,
      1.5em 1.5em;
    background-repeat: no-repeat;
    
    :hover {
      cursor: pointer;
    }

    :focus{
      background-repeat: no-repeat;
      border-color: grey;
      outline: 0;
    }
  }

  .selectText{
    margin-right:10px;
    font-weight: 500;
  }
}
`;