import React from "react";
import { Header } from "./components/molecules/header";
import { CertOverview } from "./components/organisms/cert-overview";
import { CertDetails } from "./components/organisms/cert-details";
import { CertDeploy } from "./components/organisms/cert-deploy";
import { Route, Switch } from "react-router-dom";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div id="App">
      <Header />
      <ToastContainer/>
      <Switch>
        <Route exact path="/" component={CertOverview} />
        <Route path="/overview" component={CertOverview} />
        <Route path="/details/:shaDigest" component={CertDetails} />
        <Route path="/deploy" component={CertDeploy} />
      </Switch>
    </div>
  );
}

export default App;
