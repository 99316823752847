import React  from 'react';
import { toast, Zoom} from 'react-toastify';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';

const notificationConfig = (duration) => {
  return {
    position: "top-right",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Zoom
  }
};

export const alertNotification = (type, message, duration) => {
  
    switch (type) {
        case 'warning':
            return toast.warning(<div><WarningIcon className="iconNotification"/> {message}</div>, notificationConfig(duration));
        case 'error':
            return toast.error(<div><ErrorIcon className="iconNotification"/> {message}</div>, notificationConfig(duration));
        case 'success':
            return toast.success(<div><SuccessIcon className="iconNotification"/> {message}</div>, notificationConfig(duration));
        case 'info':
            return toast.info(<div><InfoIcon className="iconNotification"/> {message}</div>, notificationConfig(duration));
        default:
            return toast(message, notificationConfig(duration));
    };
};
