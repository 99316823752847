import React from 'react';
import { StyledCACertsDistinguishedNamesRow} from './ca-deployed-names-table-row.styles';


export const CACertsDistinguishedNamesRow = ({caDeployedName}) => {
  return (
      <StyledCACertsDistinguishedNamesRow>
        <td>{caDeployedName.CN}</td>
        <td>{caDeployedName.O}</td>
        <td>{caDeployedName.OU}</td>
        <td>{caDeployedName.C}</td>
        <td>{caDeployedName.L}</td>
        <td>{caDeployedName.ST}</td>

      </StyledCACertsDistinguishedNamesRow>
  );
}