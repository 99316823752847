import moment from 'moment';

export const calculateTimeLeft = (todayDate, validToDate) => {

  let today = moment(todayDate).format('YYYY, MM, DD');

  let validDate = moment(validToDate).format('YYYY, MM, DD');

  let message = "";

  today = moment(today);
  validDate = moment(validDate);
  
  let years = validDate.diff(today, 'year');
  today.add(years, 'years');
  
  let months = validDate.diff(today, 'months');
  today.add(months, 'months');
  
  let days = validDate.diff(today, 'days');
  today.add(days, 'days');

  if(!years && ! months && !days){
    message = "Expired!"
  }
  else{
    message = `${years ? years + ' years ' : ''}  ${months ?  months + ' months ' : ''}  ${days ? days + ' days ': ''}`;
  }

  return message
}